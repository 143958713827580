<template>
  <n-dropdown trigger="hover" @select="handleSelect" :options="options">
    <n-button> Go For a Trip </n-button>
  </n-dropdown>
</template>

<script>
import { defineComponent } from 'vue'
import { useMessage } from 'naive-ui'

export default defineComponent({
  props: {
    'params': Object
  },
  setup (props) {
    const message = useMessage()
    return {
      options: props.params,
      handleSelect (key) {
        message.info(key)
      }
    }
  }
})
</script>
